import { createContext, useState, useContext, useRef } from "react";
import { UserGlobalContextMemorySpace } from "../user/userGlobalContext";

export const PrescriptionContextMemorySpace = createContext();

export const PrescriptiopnGlobalContext = ({ children }) => {
  const { user } = useContext(UserGlobalContextMemorySpace);

  const [familyGroupList, setFamilyGroupList] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [results, setResults] = useState([]);
  const [loader, setLoader] = useState(true);
  const [activeKey, setActiveKey] = useState(0);
  const [resultsTemp, setResultsTemp] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(5);

  const [currentIdSap, setCurrentIdSap] = useState(
    user.user.data.Customers.idSap
  );
  const [searchText, setSearchText] = useState("");
  const [familyItem, setFamilyItem] = useState(
    user.user.data.Customers.firstName + " " + user.user.data.Customers.lastName
  );

  return (
    <PrescriptionContextMemorySpace.Provider
      value={{
        results,
        setResults,
        familyGroupList,
        setFamilyGroupList,
        loader,
        setLoader,
        activeKey,
        setActiveKey,
        resultsTemp,
        setResultsTemp,
        total,
        setTotal,
        offset,
        setOffset,
        limit,
        filteredResults,
        setFilteredResults,
        currentIdSap,
        setCurrentIdSap,
        searchText,
        setSearchText,
        familyItem,
        setFamilyItem,
      }}
    >
      {children}
    </PrescriptionContextMemorySpace.Provider>
  );
};
