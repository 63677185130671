import React from "react";
import "./customSelect.scss";
import {
  CUSTOM_SELECT_COLOR_BLUE,
  CUSTOM_SELECT_COLOR_RED,
} from "../../common/customSelect/constants";

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSelectText: "",
      showOptionList: false,
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      defaultSelectText: this.props.defaultText,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.defaultText !== state.defaultSelectText) {
      return {
        ...state,
        defaultSelectText: props.defaultText,
      };
    }
    return null;
  }

  handleClickOutside = (e) => {
    if (
      this.containerRef.current &&
      !this.containerRef.current.contains(e.target)
    ) {
      this.setState({ showOptionList: false });
      this?.props?.onOutsideClick?.();
    }
  };

  handleListDisplay = () => {
    this.setState((prevState) => {
      return {
        showOptionList: !prevState.showOptionList,
      };
    });

    this.props.onClick();
  };

  handleOptionClick = (e) => {
    var id = e.target.getAttribute("data-id");
    var text = e.target.getAttribute("data-name");
    this.setState({
      defaultSelectText: e.target.getAttribute("data-name"),
      showOptionList: false,
    });
    this.props.onChange(id, this.props.name, text);
  };

  render() {
    const { optionsList, type, width, fontFamily, fontSize, close } =
      this.props;
    const { showOptionList, defaultSelectText } = this.state;

    const border = this.props.error ? "border-error" : "";

    return (
      <div
        ref={this.containerRef}
        style={{ width, fontFamily, fontSize }}
        className={
          showOptionList && optionsList[0].name !== "" && !close
            ? `custom-select-container-active shadow-blue ${type}`
            : `custom-select-container shadow-blue ${type} ${border}`
        }
      >
        <div
          className={
            showOptionList && optionsList[0].name !== "" && !close
              ? "selected-text active-item"
              : type === CUSTOM_SELECT_COLOR_RED
              ? "selected-text selected-text-red"
              : "selected-text"
          }
          onClick={this.handleListDisplay}
        >
          {defaultSelectText}
        </div>
        {showOptionList && optionsList[0].name !== "" && !close ? (
          <div className="split"></div>
        ) : (
          <></>
        )}
        {showOptionList && optionsList[0].name !== "" && !close && (
          <ul
            className="select-options"
            style={
              optionsList.length > 7
                ? { overflowY: "auto", maxHeight: "200px" }
                : { overflowY: "none" }
            }
          >
            {optionsList.map((option) => {
              return (
                <li
                  className={`custom-select-option`}
                  data-name={option.name}
                  key={option.id}
                  onClick={this.handleOptionClick}
                  data-id={option.id}
                >
                  {option.name}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default CustomSelect;
