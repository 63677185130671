import React from "react";

// URL estática del PDF almacenado en la carpeta public
const staticPdfUrl = "/instructivo-resultados.pdf";

const PdfViewer = () => {
  return (
    <iframe
      src={staticPdfUrl}
      width="100%"
      style={{ border: "none", height: "100vh" }}
    ></iframe>
  );
};

export default PdfViewer;
