import { useState, useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import CustomSelect from "../../../common/customSelect";
import { CUSTOM_SELECT_COLOR_BLUE } from "../../../common/customSelect/constants";

import { PrescriptionContextMemorySpace } from "../../../../contexts/prescription/prescriptionContext";

import usePagination from "../hooks/usePagination";

const HeaderSearch = () => {
  const [closeFamily, setCloseFamily] = useState(false);
  const {
    familyGroupList,
    activeKey,
    setCurrentIdSap,
    searchText,
    setSearchText,
    familyItem,
    setFamilyItem,
    setActiveKey,
  } = useContext(PrescriptionContextMemorySpace);

  const { obtenerResultados, getFilterName, searchData } = usePagination();

  const changeidSap = (id, name, text) => {
    setSearchText("");
    setFamilyItem(text);
    setCurrentIdSap(id);

    const filter = getFilterName(0);
    obtenerResultados(id, filter);

    setActiveKey(0);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const clickIdSap = () => {
    setCloseFamily(false);
  };

  return (
    <div className="row">
      <div className="item-1" id="findUserName">
        <div className="input-icons me-3" style={{ position: "relative" }}>
          <FontAwesomeIcon icon={faSearch} className="custom-icon-results" />
          <input
            value={searchText}
            className="input-field-results"
            type="text"
            placeholder="Buscar"
            onChange={searchData}
          />
        </div>
      </div>

      <div
        className="item-3 item-3-prescription"
        style={{ marginBottom: "0rem" }}
      >
        <div
          id="dropdown-name"
          style={{ position: "relative", maxWidth: "250px" }}
        >
          <CustomSelect
            defaultText={familyItem}
            optionsList={familyGroupList ?? [{ id: "", name: "" }]}
            type={CUSTOM_SELECT_COLOR_BLUE}
            width="100%"
            name="family"
            onChange={changeidSap}
            onClick={clickIdSap}
            fontFamily="Roboto-Regular"
            close={closeFamily}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderSearch;
