import React, { useContext, useState, useEffect, useRef } from "react";
import { UserGlobalContextMemorySpace } from "../../contexts/user/userGlobalContext";
import Overlay from "../../components/common/overlay";
import Swal from "sweetalert2";
import axios from "axios";
import styles from "./Apple.module.scss";
import AppleSigninButton from "react-apple-signin-auth";

// https://developers.google.com/identity/gsi/web/reference/js-reference

const AppleAuthButton = (props) => {
  const [loaderApple, setLoaderApple] = useState(false);
  const { setUserPSHB, setUser, setSocialMediaModal, setSocialMediaData } =
    useContext(UserGlobalContextMemorySpace);

  const SwalMessage = (allowOutsideClick, icon, title, text) => {
    Swal.fire({
      allowOutsideClick: allowOutsideClick,
      icon: icon,
      title: title,
      text: text,
      color: "#224d7c",
      confirmButtonColor: "#224d7c",
      confirmButtonText: "Continuar",
    });
  };

  const handleSuccess = (data) => {
    setLoaderApple(true);

    const { authorization } = data;

    if (authorization && authorization.id_token) {
      //*********** Guarda el correo para hacer el registro si no existe ***********//
      const { authorization, user } = data;
      var base64Url = authorization.id_token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      const decodedMail = JSON.parse(jsonPayload);
      //**********************//

      let date = new Date().getTime() + 60 * 60000;
      // Send the authorization code to the backend
      axios
        .post(
          `${
            process.env.REACT_APP_STRAPI_QA +
            process.env.REACT_APP_STRAPI_QA_ENDPOINT_LOGIN_APPLEAUTH
          }`,
          {
            id_token: authorization.id_token,
            pshb: false,
          }
        )
        .then((response) => {
          const respuesta = response.data.result;
          setLoaderApple(false);
          // if (respuesta.error) {
          //   SwalMessage(
          //     false,
          //     "warning",
          //     '<h5 style="color: #224d7c"></h5>',
          //     "Detectamos un problema en el inicio de sesión con Apple. Por favor, intente más tarde, o ingrese con su usuario y contraseña de HB Online."
          //   );
          // } else {
          if (respuesta.responseCode == 200) {
            setUserPSHB(false);
            setUser({ user: respuesta });
            localStorage.setItem("enc", respuesta.data.enc);
            localStorage.setItem("enc_session", date);
          } else if (
            respuesta.responseCode == 400 &&
            respuesta.data.type == "SocialMediaAccountNotLinked"
          ) {
            setSocialMediaData({
              newEmail: decodedMail.email,
              appToken: respuesta.data.appToken,
              socialMedia: "Apple",
            });
            setSocialMediaModal(true);
            // setNewEmail(decoded.data.email)
            // setAppToken(respuesta.data.appToken)
          } else if (respuesta.responseCode == 400) {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              respuesta.message
            );
          } else {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              "Detectamos un problema en el inicio de sesión con Apple. Por favor, intente más tarde, o ingrese con su usuario y contraseña de HB Online."
            );
          }
          // }
        })
        .catch((error) => {
          setLoaderApple(false);
          SwalMessage(
            false,
            "warning",
            '<h5 style="color: #224d7c"></h5>',
            "Detectamos un problema en el inicio de sesión con Apple. Por favor, intente más tarde, o ingrese con su usuario y contraseña de HB Online."
          );
          // Handle error, show message to the user, etc.
        });
    } else {
      console.error("No id_token received");
      setLoaderApple(false);
    }
  };

  const authOptions = {
    clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
    scope: "email name",
    redirectURI: process.env.REACT_APP_APPLE_REDIRECTURI,
    nonce: "nonce",
    usePopup: true,
  };

  return (
    <>
      {loaderApple ? <Overlay /> : null}
      <div
        className={`d-flex justify-content-center align-items-center shadow-blue ${styles.signInDiv} ${styles.inputApple}`}
        style={{}}
      >
        {loaderApple ? (
          <div
            style={{ marginLeft: "0px", height: "4.5vh" }}
            className="d-flex align-items-center"
          >
            <span
              style={{ color: "white" }}
              className="spinner-grow spinner-grow-sm"
            ></span>
            <span
              style={{ color: "white" }}
              className="spinner-grow spinner-grow-sm"
            ></span>
            <span
              style={{ color: "white" }}
              className="spinner-grow spinner-grow-sm"
            ></span>
          </div>
        ) : (
          <AppleSigninButton
            authOptions={authOptions}
            uiType="dark"
            className={styles.appleAuthButton} // Asigna la clase de estilo aquí
            noDefaultStyle={true}
            onSuccess={handleSuccess}
            onError={(error) => console.error("Apple sign-in error: ", error)}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className={styles.appleAuthButton}
              >
                Continuar con Apple
              </button>
            )}
          />
        )}
      </div>
    </>
  );
};

export default AppleAuthButton;
