import React from "react";
import "./customSelectMiCobertura.scss";
import Select, { components } from "react-select";

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);

    // @defaultSelectText => Show default text in select
    // @showOptionList => Show / Hide List options
    // @optionsList => List of options
    this.state = {
      defaultSelectText: "",
      showOptionList: false,
      optionsList: [],
      selectedOption: null,
    };
  }

  componentDidMount() {
    let seleccionado = this.props.optionsList
      .map((item) => {
        return { value: item.id, label: item.name };
      })
      .find((item) => item.label === this.props?.defaultText?.name);

    this.setState({
      defaultSelectText: this.props.defaultText,
      yourArray: seleccionado,
    });
  }

  // componentWillUnmount() {
  //   // Remove the event listner on component unmounting
  //   document.removeEventListener("mousedown", this.handleClickOutside);
  // }

  static getDerivedStateFromProps(props, state) {
    // Cada vez que el usuario actual cambia,
    // Reiniciar cualquier parte del estado que esté atada a ese usuario.
    // En este ejemplo, es solo email.
    // console.log(props.defaultText,state.defaultSelectText);
    if (props.defaultText !== state.defaultSelectText) {
      return {
        ...state,
        defaultSelectText: props.defaultText,
      };
    }
    return null;
  }

  // This method handles the click that happens outside the
  // select text and list area
  // handleClickOutside = (e) => {
  //   if (
  //     !e.target.classList.contains("custom-select-option") &&
  //     !e.target.classList.contains("selected-text")
  //   ) {
  //     // this.setState({
  //     //   showOptionList: false
  //     // });
  //   }
  // };

  // This method handles the display of option list
  // handleListDisplay = () => {
  //   if (this.props.optionsList.length) {
  //     this.setState((prevState) => {
  //       return {
  //         showOptionList: !prevState.showOptionList,
  //       };
  //     });
  //   }

  //   // this.props.onClick();
  // };

  // This method handles the setting of name in select text area
  // and list display on selection
  // handleOptionClick = (e) => {
  //   var id = e.target.getAttribute("data-id");
  //   var text = e.target.getAttribute("data-name");
  //   this.setState({
  //     defaultSelectText: e.target.getAttribute("data-name"),
  //     showOptionList: false,
  //   });
  //   this.props.onChange(id, this.props.name, text);
  // };

  handleChange = (selectedOption) => {
    var cualEs = this.props.name;
    var id = selectedOption.value;
    var text = selectedOption.label;
    this.setState({
      //defaultSelectText: text,
      yourArray: { value: id, label: text },
      showOptionList: false,
    });
    this.props.onChange(id, this.props.name, text);

    if (this.props.name == "socialSecurity") {
      this.props.onInputChange(cualEs);
    } else {
      this.props.onInputChangePlan(cualEs);
    }
  };

  handleChangePlan = (selectedOption) => {
    var id = selectedOption.value;
    var text = selectedOption.label;
    this.setState({
      //defaultSelectText: text,
      yourArray: { value: id, label: text },
      showOptionList: false,
    });
    this.props.onChange(id, this.props.name, text);
    this.props.onInputChangePlan();
  };

  menuClicked = () => {
    this.setState({
      yourArray: { label: "", value: "" },
    });
  };

  render() {
    const CustomDropdownIndicator = () => {
      return <div>﹀</div>;
    };

    const { optionsList, type, width, fontFamily, fontSize, close } =
      this.props;

    const objetoListaModificada = optionsList.map((objeto) => ({
      value: objeto.id,
      label: objeto.name,
    }));

    const defaultTextTT = this.props.defaultText;
    const optionsListTT = objetoListaModificada;
    const selectedOptionTT = optionsListTT.find(
      (option) => option.label === defaultTextTT
    );

    const yourArray = [];
    yourArray.push(selectedOptionTT);

    const border = this.props.error ? "border-error" : "";

    const customStyles = {
      padding: "0px",
      option: (provided, state) => ({
        ...provided,
        color: "rgb(3, 50, 97)",
        // backgroundColor: "#033261",
        "&:hover": {
          // backgroundColor: "hsl(210, 29%, 44%)",
          //borderRadius: "20px",
        },
        //borderRadius: state.isSelected ? "20px" : "0px",
        // color: state.isSelected ? "white" : "#fff",
        //backgroundColor: state.isSelected ? "#034787" : "#033261",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "rgb(3, 50, 97)",
        // backgroundColor: "hsl(209, 96%, 27%)",
        margin: "0px",
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: "0px",
      }),
      control: (provided) => ({
        ...provided,
        color: "rgb(3, 50, 97)",
        background: "transparent",
        backgroundColor: "hsl(209, 96%, 27%)",
        borderColor: "transparent",
        border: "none",
        boxShadow: "none",
      }),
      input: (provided, state) => ({
        ...provided,
        color: "rgb(3, 50, 97)", // Cambiar el color del texto de búsqueda
        borderBottom: "1px solid black",
        padding: "0px",
        margin: "0px",
      }),
      menuList: (provided, state) => ({
        ...provided,
        backgroundColor: "white",
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
        // borderRadius: "20px",
      }),
      menu: (provided, state) => ({
        ...provided,
        backgroundColor: "transparent",
        borderBottomRightRadius: "20px",
        borderBottomLeftRadius: "20px",
        // borderRadius: "20px",
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none", // Oculta la flecha
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: "white", // Oculta la flecha
      }),
    };

    return (
      <div
        style={{ width, fontFamily, fontSize }}
        className={`custom-select-containers emptyShadow ${type} ${border}`}
      >
        {this.props.name == "socialSecurity" ? (
          <Select
            autoFocus={this.props.menuOpen}
            onMenuOpen={this.props.onMenuOpen}
            onBlur={this.props.onInputChange}
            value={this.state.yourArray}
            menuIsOpen={this.props.menuOpen}
            options={objetoListaModificada}
            onChange={this.handleChange}
            styles={customStyles}
            placeholder={" "}
            components={{ DropdownIndicator: CustomDropdownIndicator }}
            // defaultValue={objetoListaModificada[0]}
            // menuIsOpen={true}
          />
        ) : (
          <Select
            isDisabled={this.props.disabled}
            autoFocus={this.props.menuOpen}
            onMenuOpen={this.props.onMenuOpen}
            onBlur={this.props.onInputChangePlan}
            value={this.state.yourArray}
            menuIsOpen={this.props.menuOpen}
            options={objetoListaModificada}
            onChange={this.handleChange}
            styles={customStyles}
            placeholder={" "}
            components={{ DropdownIndicator: CustomDropdownIndicator }}
            // defaultValue={objetoListaModificada[0]}
            // menuIsOpen={true}
          />
        )}
        {/* <div
          className={
            showOptionList && optionsList[0].name !== "" && !close
              ? "selected-text active-item"
              : type === CUSTOM_SELECT_COLOR_RED
              ? "selected-text selected-text-red"
              : "selected-text"
          }
          onClick={this.handleListDisplay}
        >
          {defaultSelectText}
        </div>
        {showOptionList && optionsList[0].name !== "" && !close ? (
          <div className="split"></div>
        ) : (
          <></>
        )}
        {showOptionList && optionsList[0].name !== "" && !close && (
          <ul
            className="select-options"
            style={
              optionsList.length > 7
                ? { overflowY: "auto", maxHeight: "200px" }
                : { overflowY: "none" }
            }
          >
            {optionsList.map((option) => {
              return (
                <li
                  className={`custom-select-option`}
                  data-name={option.name}
                  key={option.id}
                  onClick={this.handleOptionClick}
                  data-id={option.id}
                >
                  CC{option.name}
                </li>
              );
            })}
          </ul>
        )} */}
      </div>
    );
  }
}

export default CustomSelect;
