import { useContext } from "react";
import { PrescriptionContextMemorySpace } from "../../../../contexts/prescription/prescriptionContext";
import { TarjetaResultadosComponent } from "./CardMobile";
import usePagination from "../hooks/usePagination";
import useDownload from "../hooks/useDownload";

const Mobile = () => {
  const { anterior, siguiente } = usePagination();
  const { descargar } = useDownload();

  const { activeKey, resultsTemp, total, loader, offset } = useContext(
    PrescriptionContextMemorySpace
  );

  return (
    <>
      <div
        className="separador-table-red-result"
        style={{ marginBottom: "20px" }}
      ></div>

      {!loader ? (
        <div>
          {resultsTemp?.length > 0 ? (
            <div className="mt-2">
              {resultsTemp?.map((result, i) => (
                <TarjetaResultadosComponent
                  key={i}
                  result={result}
                  estudio={result.description}
                  eventDownload={() => descargar(result)}
                  showDowload={activeKey < 2}
                />
              ))}
            </div>
          ) : (
            <p
              className="section_hb_title"
              style={{
                fontSize: "16px",
                textAlign: "center",
                paddingBottom: "15px",
                paddingTop: "15px",
              }}
            >
              No se encontraron resultados con esas opciones de búsqueda.
              <br /> Intente con otros parámetros, o contáctese con nuestro
              equipo de{" "}
              <a
                href="mailto:soportewebhb@hbritanico.com.ar"
                className="section_hb_title"
                style={{
                  fontSize: "16px",
                  textDecoration: "underline",
                }}
              >
                Soporte
              </a>
              .
            </p>
          )}

          {total > 0 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {!loader ? (
                <div
                  className="btn btn-red-without-border"
                  onClick={offset === 0 ? null : anterior}
                  style={
                    offset === 0
                      ? { visibility: "hidden" }
                      : { visibility: "visible" }
                  }
                >
                  Anterior
                </div>
              ) : (
                <></>
              )}
              {!loader ? (
                <span className="number-hb-paginate">
                  <a href="#">
                    {offset / 5 + 1} de {Math.ceil(total / 5)}
                  </a>
                </span>
              ) : (
                <></>
              )}
              {!loader ? (
                <div
                  className="btn btn-red-without-border"
                  onClick={
                    offset / 5 + 1 === Math.ceil(total / 5) ? null : siguiente
                  }
                  style={
                    offset / 5 + 1 === Math.ceil(total / 5)
                      ? { visibility: "hidden" }
                      : { visibility: "visible" }
                  }
                >
                  Siguiente
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div
          className="text-center"
          style={{ paddingBottom: "15px", marginTop: "50px" }}
        >
          <span className="spinner-grow spinner-grow-sm"></span>
          <span className="spinner-grow spinner-grow-sm"></span>
          <span className="spinner-grow spinner-grow-sm"></span>
        </div>
      )}
    </>
  );
};

export default Mobile;
