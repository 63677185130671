import { myAxios } from "../../../../utils/fetch";
import { useEffect, useContext } from "react";
import { PrescriptionContextMemorySpace } from "../../../../contexts/prescription/prescriptionContext";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";

let text = "";

const usePagination = () => {
  const {
    setResults,
    results,
    loader,
    setLoader,
    resultsTemp,
    setResultsTemp,
    total,
    setTotal,
    offset,
    setOffset,
    limit,
    filteredResults,
    setFilteredResults,
    setSearchText,
  } = useContext(PrescriptionContextMemorySpace);
  const { user } = useContext(UserGlobalContextMemorySpace);

  const obtenerResultados = (idSap, filter) => {
    setResults([]);
    setLoader(true);

    const query = `idSap=${idSap}&filter=${filter}`;
    const url = `/patient/api/v1/results/getprescriptionsbysap?${query}`;

    myAxios({
      url: process.env.REACT_APP_GATEWAY_QA + url,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    })
      .then((response) => {
        setTotal(response.data.data.prescriptions.length);
        setLoader(false);
        setResults(response.data.data.prescriptions);
      })
      .catch((error) => {
        setResults([]);
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
        setOffset(0);
      });
  };

  useEffect(() => {
    setTotal(results?.length);
    setResultsTemp(results?.slice(0, limit));
  }, [results]);

  useEffect(() => {
    if (text) {
      setResultsTemp(filteredResults?.slice(offset, offset + limit));
      return;
    }
    setResultsTemp(results?.slice(offset, offset + limit));
  }, [offset]);

  const siguiente = () => {
    if (offset + limit < total) {
      setOffset(offset + limit);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const anterior = () => {
    const temporalOffset = offset - limit;
    if (temporalOffset >= 0) {
      setOffset(temporalOffset);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const searchData = (event) => {
    setSearchText(event.target.value);
    text = event.target.value.toLowerCase();

    const filter = results.filter(
      (item) =>
        item.droga.toLowerCase().includes(text) ||
        item.presentation.toLowerCase().includes(text)
    );

    setFilteredResults(filter);
    setResultsTemp(filter?.slice(0, limit)); // Lo que muestra la tabla

    setTotal(filter?.length);
    setOffset(0);
  };

  const getFilterName = (key) => {
    switch (key) {
      case 0:
        return "VIGENTES";
      case 1:
        return "FUTURAS";
      case 2:
        return "VENCIDAS";
      default:
        break;
    }
  };

  return {
    results,
    resultsTemp,
    loader,
    total,
    offset,
    limit,
    siguiente,
    anterior,
    obtenerResultados,
    getFilterName,
    searchData,
  };
};

export default usePagination;
