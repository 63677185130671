import "./style.scss";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import PrescriptionsComponent from "../../../components/hb/prescriptions";
import { PopupValidateSap } from "../../../components/common/popup-validate-sap";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { PrescriptiopnGlobalContext } from "../../../contexts/prescription/prescriptionContext";
import useValidProfile from "../../../hooks/useValidProfile";

const Prescriptions = () => {
  const { user } = useContext(UserGlobalContextMemorySpace);
  const { showPopup } = useValidProfile(user);

  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <PrescriptiopnGlobalContext>
      <div className="d-flex help-page" ref={ref}>
        {/* {showPopup ? (
          <PopupValidateSap message="Para ver resultados" visible={true} />
        ) : ( */}
        <PrescriptionsComponent />
        {/* )} */}
      </div>
    </PrescriptiopnGlobalContext>
  );
};

export default Prescriptions;
