import React from "react";
import download from "../../../../assets/img/card-icons/descargar.svg";

export const TarjetaResultadosComponent = ({
  result,
  eventDownload,
  showDowload,
}) => {
  function formatDate(value) {
    value = value?.split("T")[0];
    const chunks = value.split("-");
    const format = `${chunks[2]}-${chunks[1]}-${chunks[0]}`;
    return format;
  }

  return (
    <div className={"tarjeta--activa shadow mt-2"}>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div>
          <span className="resultado-etiqueta">Válida desde:</span>
          <span className="resultado-valor">
            {formatDate(result.validFrom)}
          </span>
        </div>
        <div>
          <span className="resultado-etiqueta">Droga:</span>
          <span className="resultado-valor">{result.droga}</span>
        </div>
        <div>
          <span className="resultado-etiqueta">Presentación:</span>
          <span className="resultado-valor">{result.presentation}</span>
        </div>
        <div>
          <span className="resultado-etiqueta">Cantidad:</span>
          <span className="resultado-valor">{result.quantity}</span>
        </div>
      </div>
      <div
        class="separador"
        style={{ width: "100%", borderTopWidth: "2px" }}
      ></div>
      <div className="iconos-acciones-tarjeta">
        {showDowload && (
          <img
            src={download}
            alt="Descargar"
            onClick={() => eventDownload(result)}
          />
        )}
      </div>
    </div>
  );
};
