import { useContext } from "react";
import Swal from "sweetalert2";
import { myAxios, fetchLogs } from "../../../../utils/fetch";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";

const useDownload = () => {
  const { user } = useContext(UserGlobalContextMemorySpace);

  const descargar = (prescription) => {
    const query = `idPrescription=${prescription.id}`;
    const url = `/patient/api/v1/results/GetPDFSAP?${query}`;

    myAxios({
      url: process.env.REACT_APP_GATEWAY_QA + url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    })
      .then((response) => {
        const base64File = response?.data?.data?.prescription;
        if (!base64File) {
          Swal.fire({
            icon: "info",
            title: '<h5 style="color: #224d7c"></h5>',
            text: "Disculpe, no se pudo descargar la receta. Por favor, intente nuevamente más tarde.",
            confirmButtonText: "Continuar",
          });
          return;
        }
        fetchLogs({
          idLogType: 1,
          idType: 7,
          description:
            "El usuario " +
            user.user.data.Customers.lastName +
            " " +
            user.user.data.Customers.firstName +
            " bajó la receta receta_" +
            prescription.id,
        });
        const isSafariOnIOS = () => {
          const userAgent = navigator.userAgent;
          return (
            /iPhone|iPad|iPod/.test(userAgent) &&
            !/CriOS/.test(userAgent) &&
            /Safari/.test(userAgent)
          );
        };
        if (isSafariOnIOS()) {
          const byteCharacters = atob(base64File);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          // Crear un objeto URL para el blob
          const url = window.URL.createObjectURL(blob);
          // Crear un enlace y simular un clic para descargar el archivo
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.setAttribute(
            "download",
            `receta_${prescription.id}.pdf`
          );
          document.body.appendChild(downloadLink);
          downloadLink.download = `receta_${prescription.id}.pdf`;
          downloadLink.click();
          // Liberar el objeto URL creado
          window.URL.revokeObjectURL(url);
        } else {
          const linkSource = `data:application/pdf;base64,${base64File}`;
          const downloadLink = document.createElement("a");
          const fileName = `receta_${prescription.id}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      })
      .catch((error) => {});
  };

  return { descargar };
};

export default useDownload;
