import React, { useContext, useState } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import style from "./style.scss";
// import logo from '../../../assets/img/hb/Isologotipo.png'
import bgImageLogin from "./../../../assets/img/hb/image-login.jpg";
import bgImageLoginPSHB from "./../../../assets/img/hb/test2.jpg";
import logo from "../../../assets/img/logos/logo-hospital.png";
import { Link, useNavigate } from "react-router-dom";
import {
  signInWithGoogle,
  signInWithFacebook,
  auth,
  providerFacebook,
  providerGoogle,
} from "../../../utils/firebase";
import {
  getAuth,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithCredential,
} from "firebase/auth";
import Swal from "sweetalert2";
import axios from "axios";
import { emailValidator } from "../../../utils/hooks/regex";
import GoogleAuthButton from "../../../containers/google";
import AppleAuthButton from "../../../containers/apple";
import FacebookAuthButton from "../../../containers/facebook";
import MockUser from "../../../utils/mock-user/mock-user";
import Overlay from "../../../components/common/overlay";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";
import useProgressiveImage from "../../../hooks/useProgressiveImage";
import { fetchLogs } from "../../../utils/fetch";
import AppleSigninButton from "react-apple-signin-auth";

// Login ByPass sin credenciales
/*const handleLoginPSHB/HB = () =>{
    setUserPSHB(true);
    setUser({user: "PSHB User"})*/

const LoginHB = (props) => {
  const [loader, setLoader] = useState(false);
  const [loaderGoogle, setLoaderGoogle] = useState(false);
  const [loaderFacebook, setLoaderFacebook] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [googleButtonDisabled, setGoogleButtonDisabled] = useState(false);
  const [facebookButtonDisabled, setFacebookButtonDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const loadedBgImage = useProgressiveImage(bgImageLogin);

  const [passwordShown, setPasswordShown] = useState(false);

  const {
    sitePSHB,
    setSitePSHB,
    userPSHB,
    setUserPSHB,
    user,
    setUser,
    setOriginalUser,
    socialMediaModal,
  } = useContext(UserGlobalContextMemorySpace);

  const navigate = useNavigate();

  const disabledAllButtons = () => {
    setButtonDisabled(true);
    setGoogleButtonDisabled(true);
    setFacebookButtonDisabled(true);
  };

  const enableAllButtons = () => {
    setButtonDisabled(false);
    setGoogleButtonDisabled(false);
    setFacebookButtonDisabled(false);
  };

  const handleLoginHB = (event) => {
    event.preventDefault();
    // setTimeout(() => {
    //     enableAllButtons();
    //     setUserPSHB(false);
    //     setUser({user: "HB User"})
    //     setLoader(false)
    // }, 3000);
    // setUserPSHB(false);
    // setUser({user: "HB User"})
    setErrorMsg("");
    if (!email || !password || socialMediaModal) {
      // Estilos para marcar al usuario
      if (!socialMediaModal) {
        setErrorMsg(
          "* Por favor ingresa usuario y contraseña para iniciar sesión."
        );
      }
    } else {
      if (emailValidator(email)) {
        setErrorMsg("* Por favor ingresa un formato de email valido");
      } else {
        disabledAllButtons();
        setLoader(true);
        let body = {
          email: email,
          password: password,
          pshb: false,
        };

        let date = new Date().getTime() + 60 * 60000;
        localStorage.clear();

        axios({
          url:
            process.env.REACT_APP_STRAPI_QA +
            process.env.REACT_APP_STRAPI_QA_ENDPOINT_LOGIN,
          method: "POST",
          data: body,
        })
          .then((response) => {
            if (response.data.responseCode == 200) {
              setLoader(false);
              enableAllButtons();
              setUserPSHB(false);
              setUser({ user: response.data });
              setOriginalUser({ user: response.data });
              localStorage.setItem("enc", response.data.data.enc);
              localStorage.setItem("enc_session", date);
              // const redirect=sessionStorage.getItem("redirect");
              // if(redirect)
              // {
              //     sessionStorage.removeItem("redirect");
              //     navigate(redirect, { replace: true });
              // }
            } else if (response.data.responseCode == 400) {
              if (response.data.data.type === "UserNotValidated") {
                fetchLogs({
                  idLogType: 1,
                  idType: 15,
                  description:
                    "Han intentado acceder al Portal Web con el correo " +
                    email +
                    " pero el email no ha sido validado",
                });
              } else if (response.data.data.type === "UserNoExist") {
                fetchLogs({
                  idLogType: 1,
                  idType: 15,
                  description:
                    "Han intentado acceder al Portal Web con el correo " +
                    email +
                    " pero el email no existe",
                });
              }
              setLoader(false);
              enableAllButtons();
              SwalMessage(
                false,
                "warning",
                '<h5 style="color: #224d7c"></h5>',
                response.data.message
              );
            } else {
              setLoader(false);
              enableAllButtons();
              SwalMessage(
                false,
                "warning",
                '<h5 style="color: #224d7c"></h5>',
                "Lo sentimos. Estamos trabajando para solucionar problemas técnicos en nuestro sitio. Por favor, intente nuevamente más tarde."
              );
            }
          })
          .catch((error) => {
            setLoader(false);
            enableAllButtons();
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              "Lo sentimos. Estamos trabajando para solucionar problemas técnicos en nuestro sitio. Por favor, intente nuevamente más tarde."
            );
          });
      }
    }
  };

  const SwalMessage = (allowOutsideClick, icon, title, text) => {
    Swal.fire({
      allowOutsideClick: allowOutsideClick,
      icon: icon,
      title: title,
      text: text,
      color: "#224d7c",
      confirmButtonColor: "#224d7c",
      confirmButtonText: "Continuar",
    });
  };

  const handlerSignInWithGoogle = async (event) => {
    event.preventDefault();
    disabledAllButtons();
    setLoaderGoogle(true);
    let idToken = await signInWithPopup(auth, providerGoogle)
      .then((result) => {
        //const idToken = result._tokenResponse.idToken;
        return result;
      })
      .catch((error) => {
        setLoaderGoogle(false);
        enableAllButtons();
        SwalMessage(
          false,
          "warning",
          '<h5 style="color: #224d7c"></h5>',
          "Detectamos un problema en el inicio de sesión con Google. Por favor, intente más tarde, o ingrese con su usuario y contraseña de HB Online."
        );
      });
    axios({
      url:
        process.env.REACT_APP_STRAPI_QA +
        process.env.REACT_APP_STRAPI_QA_ENDPOINT_LOGIN_SOCIALMEDIA,
      method: "POST",
      data: { idToken: idToken._tokenResponse.idToken },
    })
      .then((response) => {
        setLoaderGoogle(false);
        enableAllButtons();
        if (response.data.error) {
          SwalMessage(
            false,
            "warning",
            '<h5 style="color: #224d7c"></h5>',
            "Detectamos un problema en el inicio de sesión con Google. Por favor, intente más tarde, o ingrese con su usuario y contraseña de HB Online."
          );
        } else {
          if (response.data.result.responseCode == 200) {
            setUserPSHB(false);
            setUser({ user: response.data });
          } else if (response.data.result.responseCode == 400) {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              response.data.result.message
            );
          } else {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              "Detectamos un problema en el inicio de sesión con Google. Por favor, intente más tarde, o ingrese con su usuario y contraseña de HB Online."
            );
          }
        }
      })
      .catch((error) => {
        setLoaderGoogle(false);
        enableAllButtons();
        SwalMessage(
          false,
          "warning",
          '<h5 style="color: #224d7c"></h5>',
          "Detectamos un problema en el inicio de sesión con Google. Por favor, intente más tarde, o ingrese con su usuario y contraseña de HB Online."
        );
      });
  };

  const handlerSignInWithFacebook = async (event) => {
    event.preventDefault();
    disabledAllButtons();
    setLoaderFacebook(true);
    let idToken = await signInWithPopup(auth, providerFacebook)
      .then((result) => {
        //const idToken = result._tokenResponse.idToken;
        return result;
      })
      .catch((error) => {
        enableAllButtons();
        setLoaderFacebook(false);
        SwalMessage(
          false,
          "warning",
          '<h5 style="color: #224d7c"></h5>',
          "Detectamos un problema en el inicio de sesión con Facebook, por favor intenta con tu usuario y contraseña."
        );
      });
    axios({
      url:
        process.env.REACT_APP_STRAPI_QA +
        process.env.REACT_APP_STRAPI_QA_ENDPOINT_LOGIN_SOCIALMEDIA,
      method: "POST",
      data: { idToken: idToken._tokenResponse.idToken },
    })
      .then((response) => {
        enableAllButtons();
        setLoaderFacebook(false);
        if (response.data.error) {
          SwalMessage(
            false,
            "warning",
            '<h5 style="color: #224d7c"></h5>',
            "Detectamos un problema en el inicio de sesión con Facebook, por favor intenta con tu usuario y contraseña."
          );
        } else {
          if (response.data.result.responseCode == 200) {
            setUserPSHB(false);
            setUser({ user: response.data });
          } else if (response.data.result.responseCode == 400) {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              response.data.result.message
            );
          } else {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              "Detectamos un problema en el inicio de sesión con Facebook, por favor intenta con tu usuario y contraseña."
            );
          }
        }
      })
      .catch((error) => {
        enableAllButtons();
        setLoaderFacebook(false);
        SwalMessage(
          false,
          "warning",
          '<h5 style="color: #224d7c"></h5>',
          "Detectamos un problema en el inicio de sesión con Facebook, por favor intenta con tu usuario y contraseña."
        );
      });
  };

  const eventoOlvido = () => {
    // ReactGA.event({
    //     category: "Acciones de Inicio de Sesión",
    //     action: "click",
    //     label: "Password"
    //   });
  };

  const eventoRegistrarse = () => {
    // ReactGA.event({
    //     category: "Acciones de Inicio de Sesión",
    //     action: "click",
    //     label: "Registro"
    //   });
  };

  return (
    <>
      {loader ? <Overlay /> : null}
      <section>
        <form onSubmit={handleLoginHB}>
          <div
            className="d-flex align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="div-login">
              <div className="d-flex flex-column align-items-center">
                <img className="img-logo" src={logo}></img>
                <div className="br"></div>
                <h5 className="default-fontColor">Iniciar Sesión</h5>
                <div className="br"></div>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="input-login shadow-blue input-login-v2"
                  placeholder="E-mail"
                ></input>
                <div className="br"></div>

                <div
                  className="input-login shadow-blue  input-login-v2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    height: "42px",
                  }}
                >
                  <input
                    style={{
                      width: "100%",
                      height: "100%",
                      marginLeft: "-8px",
                      paddingLeft: "10px",
                      borderRadius: "8px",
                    }}
                    className="custom-input-login"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type={passwordShown ? "text" : "password"}
                    placeholder="Contraseña"
                  ></input>
                  <FontAwesomeIcon
                    style={{ right: "12px", position: "absolute" }}
                    icon={passwordShown ? faEyeSlash : faEye}
                    onClick={() => setPasswordShown((previus) => !previus)}
                  />
                </div>

                <div className="br"></div>
                <div className="br"></div>

                {loader ? (
                  <button
                    disabled={true}
                    type="submit"
                    className="button-loader shadow-blue"
                  >
                    <span className="spinner-grow spinner-grow-sm"></span>
                    <span className="spinner-grow spinner-grow-sm"></span>
                    <span className="spinner-grow spinner-grow-sm"></span>
                  </button>
                ) : (
                  <input
                    disabled={buttonDisabled}
                    className="button-continuar shadow-blue"
                    type="submit"
                    value="Continuar"
                  />
                )}

                <div
                  className="mt-2 button-outline-parent"
                  style={{ position: "relative" }}
                >
                  <Link
                    to={"/register"}
                    className="button-outline-login"
                    onClick={() => eventoRegistrarse()}
                  >
                    <div className="button-outline-login-contain"></div>
                    Registrarse
                  </Link>
                </div>

                <Link
                  to={"/password-recovery"}
                  className="link-noStyle mt-3"
                  onClick={() => eventoOlvido()}
                >
                  ¿Olvidaste tu contraseña?
                </Link>

                <div className="d-flex justify-content-between align-items-center hr-box mt-4">
                  <div>
                    <hr className="hr-login" />
                  </div>
                  <div className="dot"></div>
                  <div>
                    <hr className="hr-login" />
                  </div>
                </div>

                {/* <FacebookAuthButton pshb={false} /> */}
                <div className="br"></div>
                <div style={{}} className="googleBox">
                  <GoogleAuthButton pshb={false}></GoogleAuthButton>
                </div>

                <div className="br"></div>
                <div style={{}} className="appleBox">
                  <AppleAuthButton></AppleAuthButton>
                </div>

                {errorMsg ? <div className="br"></div> : null}
                <p style={{ color: "red", fontWeight: "500" }}>{errorMsg}</p>
              </div>
            </div>
            <div
              className="bg-image"
              style={{ backgroundImage: `url(${loadedBgImage || ""})` }}
            >
              <div className="bg-image-text">
                <div className="bg-image-text">
                  <div className="transbox">
                    <h1>Bienvenido a HB Online</h1>
                    <h5>
                      Agendá tus turnos, accedé a tus resultados y gestioná tu
                      grupo familiar desde el portal del Hospital Británico
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default LoginHB;
