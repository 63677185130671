import { useContext } from "react";
import DownloadIcon from "../../../../assets/img/card-icons/descargar.svg";
import { PrescriptionContextMemorySpace } from "../../../../contexts/prescription/prescriptionContext";
import usePagination from "../hooks/usePagination";
import useDownload from "../hooks/useDownload";

const Desktop = () => {
  const { anterior, siguiente } = usePagination();
  const { descargar } = useDownload();
  const { activeKey, resultsTemp, total, loader, offset } = useContext(
    PrescriptionContextMemorySpace
  );

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function formatDate(value) {
    value = value?.split("T")[0];
    const chunks = value.split("-");
    const format = `${chunks[2]}-${chunks[1]}-${chunks[0]}`;
    return format;
  }

  return (
    <>
      {/* SEPARADOR */}
      <div
        className="separador-table-red-result"
        id="separator-top-results"
      ></div>
      {/* TABLA */}
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="table-responsive">
            {!loader ? (
              <table className="table table-hb" id="tablePrescriptions">
                <thead>
                  <tr>
                    {resultsTemp?.length > 0 && (
                      <>
                        <th>Id</th>
                        <th>Válida desde</th>
                        <th>Droga</th>
                        <th>Presentación</th>
                        <th>Cantidad</th>
                        {activeKey < 2 && <th>Acciones</th>}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {resultsTemp?.length > 0 ? (
                    resultsTemp.map((result, i) => {
                      return (
                        <tr key={i}>
                          <td>{result.id.replace(/^0+/, "")}</td>
                          <td>{formatDate(result.validFrom)}</td>
                          <td>{capitalizeFirstLetter(result.droga)}</td>
                          <td>{result.presentation}</td>
                          <td>{result.quantity}</td>
                          {activeKey < 2 && (
                            <td>
                              <img
                                onClick={() => descargar(result)}
                                className="i-25-result"
                                src={DownloadIcon}
                                alt="..."
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <td
                      colSpan="5"
                      style={{
                        textAlign: "center",
                        paddingBottom: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      <p
                        className="section_hb_title"
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        No se encontraron resultados con esas opciones de
                        búsqueda.
                        <br /> Intente con otros parámetros, o contáctese con
                        nuestro equipo de{" "}
                        <a
                          href="mailto:soportewebhb@hbritanico.com.ar"
                          className="section_hb_title"
                          style={{
                            fontSize: "16px",
                            textDecoration: "underline",
                          }}
                        >
                          Soporte
                        </a>
                        .
                      </p>
                    </td>
                  )}
                </tbody>
              </table>
            ) : (
              <div className="text-center" style={{ paddingBottom: "15px" }}>
                <span className="spinner-grow spinner-grow-sm"></span>
                <span className="spinner-grow spinner-grow-sm"></span>
                <span className="spinner-grow spinner-grow-sm"></span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* SEPARADOR */}
      <div className="separador-table-red-result"></div>
      {/* offset: {offset} - total: {total} - resultsTemp: {resultsTemp.length}  - filteredResults: {filteredResults.length} */}
      {total > 0 ? (
        <div id="paginate">
          {(offset > 0) & !loader ? (
            <div className="btn btn-red-without-border" onClick={anterior}>
              Anterior
            </div>
          ) : (
            <></>
          )}
          {!loader ? (
            <span className="number-hb-paginate">
              <a href="#">
                {offset / 5 + 1} de {Math.ceil(total / 5)}
              </a>
            </span>
          ) : (
            <></>
          )}
          {(offset + resultsTemp.length < total) & !loader ? (
            <div className="btn btn-red-without-border" onClick={siguiente}>
              Siguiente
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Desktop;
