import "./style.scss";

import React, { useContext, useLayoutEffect, useRef, useEffect } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { PrescriptionContextMemorySpace } from "../../../contexts/prescription/prescriptionContext";
import { myAxios, fetchLogs } from "../../../utils/fetch";

import HeaderSearch from "./components/HeaderSearch";
import Desktop from "./components/Desktop";
import Mobile from "./components/Mobile";
import usePagination from "./hooks/usePagination";

const ResultsComponent = () => {
  const { user } = useContext(UserGlobalContextMemorySpace);
  const { setFamilyGroupList, setSearchText } = useContext(
    PrescriptionContextMemorySpace
  );
  const [customers, setCustomers] = React.useState([user.user.data.Customers]);
  const { obtenerResultados, getFilterName } = usePagination();
  const refBusqueda = useRef();

  const { activeKey, setActiveKey, currentIdSap } = useContext(
    PrescriptionContextMemorySpace
  );

  useLayoutEffect(() => {
    fetchLogs({
      idLogType: 1,
      idType: 18,
      description:
        "El usuario " +
        user.user.data.Customers.lastName +
        " " +
        user.user.data.Customers.firstName +
        " accedió al área de recetas",
    });
  }, []);

  const toggleAccordion = (key) => {
    if (key === activeKey) return;

    const filter = getFilterName(key);
    obtenerResultados(currentIdSap, filter);

    setSearchText("");
    window.scrollTo({ top: 0, behavior: "smooth" });
    setActiveKey(activeKey === key ? null : key);
  };

  useEffect(() => {
    myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/familiargroup/getfamiliargroupbyid",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: {
        idCustomer: user.user.data.Customers.idCustomer,
      },
    })
      .then((response) => {
        const familyGroup = response.data.data.FamilyGroup.map((family) => {
          return { id: family.idSap ?? "", name: family.fullName };
        });
        var temporalFamilyGroup = [
          {
            id: user.user.data.Customers.idSap,
            name:
              user.user.data.Customers.firstName +
              " " +
              user.user.data.Customers.lastName,
          },
          ...familyGroup,
        ];
        setCustomers([...customers, ...response.data.data.FamilyGroup]);
        setFamilyGroupList(temporalFamilyGroup);
      })
      .catch((error) => {})
      .finally(() => {
        obtenerResultados(user.user.data.Customers.idSap, "VIGENTES");
      });
  }, []);

  return (
    <section className=" " ref={refBusqueda}>
      <div className="container" id="container-prescripcions">
        <div className="accordion" id="accordionExample">
          {/* RECETAS VIGENTES */}
          <div>
            <div className="row mb-3">
              <div className="col-lg-5 main-title-recetas">
                <h2 className="section_hb_title title-results">
                  <b
                    id="headingOne"
                    className="section_hb_title title_prescription mouse-pointer"
                    onClick={() => toggleAccordion(0)}
                    aria-expanded={activeKey === 0}
                    aria-controls="collapseOne"
                  >
                    Recetas vigentes
                    {activeKey == 0 ? (
                      <i className="bi bi-chevron-up ico-row-recetas" />
                    ) : (
                      <i className="bi bi-chevron-down ico-row-recetas" />
                    )}
                  </b>
                </h2>
              </div>

              <div className="col-lg-1"></div>
            </div>

            <div
              id="collapseOne"
              className={`collapse ${activeKey === 0 ? "show" : ""}`}
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div style={{ marginBottom: 60 }}>
                <HeaderSearch />
              </div>
              <div
                class="d-block d-md-none w-100"
                style={{ marginTop: "15px" }}
              >
                <Mobile />
              </div>

              <div class="d-none d-md-block w-100">
                <Desktop />
              </div>
            </div>
          </div>

          {(activeKey === 1 || activeKey === 2) && (
            <>
              <div style={{ marginBottom: 60 }}>
                <HeaderSearch />
              </div>
              <div
                className="separador-table-red-result"
                style={{ marginTop: "80px !important" }}
              ></div>
            </>
          )}

          {/* RECETAS FUTURAS */}
          <div>
            <div className="row mb-3">
              <div className="col-lg-5 mt-3">
                <h2 className="section_hb_title title-results">
                  <b
                    id="headingTwo"
                    className="section_hb_title title_prescription mouse-pointer"
                    onClick={() => toggleAccordion(1)}
                    aria-expanded={activeKey === 1}
                    aria-controls="collapseTwo"
                  >
                    Recetas futuras
                    {activeKey == 1 ? (
                      <i className="bi bi-chevron-up ico-row-recetas" />
                    ) : (
                      <i className="bi bi-chevron-down ico-row-recetas" />
                    )}
                  </b>
                </h2>
              </div>

              <div className="col-lg-1"></div>
            </div>

            <div
              id="collapseTwo"
              className={`collapse ${activeKey === 1 ? "show" : ""}`}
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div
                class="d-block d-md-none w-100"
                style={{ marginTop: "15px" }}
              >
                <Mobile />
              </div>

              <div class="d-none d-md-block w-100">
                <Desktop />
              </div>
            </div>
          </div>

          {/* RECETAS VENCIDAS */}
          <div>
            <div className="row mb-3">
              <div className="col-lg-5 mt-3">
                <h2 className="section_hb_title title-results">
                  <b
                    id="headingThree"
                    className="section_hb_title title_prescription mouse-pointer"
                    onClick={() => toggleAccordion(2)}
                    aria-expanded={activeKey === 2}
                    aria-controls="collapseThree"
                  >
                    Recetas vencidas
                    {activeKey == 2 ? (
                      <i className="bi bi-chevron-up ico-row-recetas" />
                    ) : (
                      <i className="bi bi-chevron-down ico-row-recetas" />
                    )}
                  </b>
                </h2>
              </div>

              <div className="col-lg-1"></div>
            </div>

            <div
              id="collapseThree"
              className={`collapse ${activeKey === 2 ? "show" : ""}`}
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div
                class="d-block d-md-none w-100"
                style={{ marginTop: "15px" }}
              >
                <Mobile />
              </div>

              <div class="d-none d-md-block w-100">
                <Desktop />
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <p>
                <strong>Importante:</strong> solo las recetas vigentes y futuras
                están disponibles para descagar
              </p>
            </div>

            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultsComponent;
