import "./dato-contacto.scss";
import { FiEdit2, FiX, FiCheck } from "react-icons/fi";
import React, { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";
import { Formik, useFormik } from "formik";
import { validateModel } from "./validationSchema";

export const DatoContactoComponent = ({
  activo = false,
  editando = false,
  guardando,
  onEditando = () => null,
  confirmar = () => null,
  cancelar = () => null,
}) => {
  const { userPSHB, user, originalUser, setOriginalUser } = useContext(
    UserGlobalContextMemorySpace
  );

  const [claseCampo, setClaseCampo] = React.useState("");
  const [temporalUser, setTemporalUser] = React.useState(
    JSON.parse(JSON.stringify(user))
  );
  const [isDisableSend, setIsDisableSend] = React.useState(true);

  React.useEffect(() => {
    setIsDisableSend(true);
    if (editando) setClaseCampo("campo");
    else setClaseCampo("campo");
  }, [editando]);

  React.useEffect(() => {
    if (!temporalUser || !originalUser) return;

    const usr = originalUser?.user?.data?.Customers;
    const tmp = temporalUser?.user?.data?.Customers;
    if (usr.mobile != tmp.mobile || usr.phone != tmp.phone) {
      setIsDisableSend(false);
    } else {
      setIsDisableSend(true);
    }
  }, [temporalUser, originalUser]);

  const changeValue = (e) => {
    if (e.target.name === "phone" || e.target.name === "mobile") {
      const { value } = e.target;
      const regex = /^[0-9]$/;
      if (!regex.test(value.at(-1))) {
        e.target.value = value.substring(0, value.length - 1);
      }
    }
    temporalUser.user.data.Customers = {
      ...temporalUser.user.data.Customers,
      [e.target.name]: e.target.value,
    };
    setTemporalUser(JSON.parse(JSON.stringify(temporalUser)));
    formik.setFieldValue([e.target.name], e.target.value);
  };

  const initialValues = {
    phone: temporalUser.user.data.Customers.phone,
    mobile: temporalUser.user.data.Customers.mobile,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateModel,
  });

  const handleConfirmar = (values) => {
    setOriginalUser(values);
    setIsDisableSend(true);
    confirmar(values);
  };

  return (
    <>
      {/* Componente para dispositivos móviles (oculto en pantallas medianas y grandes) */}
      <div class="d-block d-md-none">
        {/* INICIO MOBILE */}
        <div className={activo ? "" : "d-none"}>
          <div className={activo ? "formActivo" : "formInactivo"}>
            <form
              className={userPSHB ? "formPSHB" : "formHB"}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "7px",
                paddingTop: "30px",
              }}
            >
              <div style={{ display: "block" }}>
                <span className="perfil-etiqueta me-1">Teléfono fijo:</span>
                {!editando ? (
                  <span className="perfil-valor">
                    {temporalUser.user.data.Customers.phone}
                  </span>
                ) : (
                  <input
                    type="text"
                    className="underlined-input"
                    name="phone"
                    maxLength={12}
                    value={temporalUser.user.data.Customers.phone}
                    onChange={changeValue}
                    onBlur={changeValue}
                    style={{ maxWidth: "120px" }}
                    //autoFocus={true}
                  />
                )}
              </div>
              <div className="text-danger ms-2">{formik.errors.phone}</div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "125px calc(100% - 135px)",
                }}
              >
                <span className="perfil-etiqueta me-1">Teléfono celular:</span>

                {!editando ? (
                  <span className="perfil-valor">
                    {temporalUser.user.data.Customers.mobile}
                  </span>
                ) : (
                  <input
                    type="text"
                    className="underlined-input"
                    name="mobile"
                    maxLength={12}
                    value={temporalUser.user.data.Customers.mobile}
                    onChange={changeValue}
                    onBlur={changeValue}
                    style={{ maxWidth: "95px" }}
                    //autoFocus={true}
                  />
                )}
              </div>
              <div className="text-danger ms-2">{formik.errors.mobile}</div>

              <div>
                <span className="perfil-etiqueta me-1">Mail:</span>
                <span className="perfil-valor" style={{ fontSize: "14px" }}>
                  {temporalUser.user.data.Customers.email}
                </span>
              </div>
              <div className="text-danger ms-2">{formik.errors.email}</div>
            </form>
            {/* BOTONES DE EDITAR, CANCELAR, CONFIRMAR */}
            <div
              className=""
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              {!editando ? (
                <button
                  className="botonEditar"
                  style={{ width: "100px" }}
                  onClick={() => (activo ? onEditando() : null)}
                >
                  <FiEdit2 /> Editar
                </button>
              ) : (
                <>
                  <button
                    className={`${
                      isDisableSend ? "botonEditar-disable" : "botonEditar"
                    } ${userPSHB ? "boton-PSHB" : "boton-HB"}`}
                    disabled={isDisableSend}
                    onClick={() =>
                      guardando ? null : handleConfirmar(temporalUser)
                    }
                  >
                    {guardando ? (
                      <>
                        <div
                          style={{
                            verticalAlign: "inherit",
                            marginLeft: "5px",
                            width: "0.7rem",
                            height: "0.7rem",
                            color: "rgb(93, 132, 170)",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                        <div
                          style={{
                            verticalAlign: "inherit",
                            marginLeft: "5px",
                            width: "0.7rem",
                            height: "0.7rem",
                            color: "rgb(93, 132, 170)",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                        <div
                          style={{
                            verticalAlign: "inherit",
                            marginLeft: "5px",
                            width: "0.7rem",
                            height: "0.7rem",
                            color: "rgb(93, 132, 170)",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                      </>
                    ) : (
                      <>
                        <FiCheck /> Confirmar
                      </>
                    )}
                  </button>
                  <button
                    className="botonCancelar"
                    onClick={() => cancelar(setTemporalUser)}
                  >
                    <FiX /> Cancelar
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        {/* FIN MOBILE */}
      </div>

      {/* Componente para pantallas medianas y grandes (oculto en dispositivos móviles) */}
      <div class="d-none d-md-block">
        {/* INICIO DESKTOP */}
        <div className={activo ? "" : "d-none"}>
          <div className={activo ? "formActivo" : "formInactivo"}>
            {/* LINEA SUPERIOR */}
            <div
              className={
                activo
                  ? "lineaCabActiva"
                  : userPSHB
                  ? "lineaCabInactivaPSHB"
                  : "lineaCabInactiva"
              }
            />

            {/* BOTONES DE EDITAR, CANCELAR, CONFIRMAR */}
            <div className="botones">
              {!editando && (
                <button
                  className="botonEditar"
                  onClick={() => (activo ? onEditando() : null)}
                >
                  <FiEdit2 /> Editar
                </button>
              )}
              {editando && (
                <>
                  <button
                    className={`${
                      isDisableSend ? "botonEditar-disable" : "botonEditar"
                    } ${userPSHB ? "boton-PSHB" : "boton-HB"}`}
                    disabled={isDisableSend}
                    onClick={() =>
                      guardando ? null : handleConfirmar(temporalUser)
                    }
                  >
                    {guardando ? (
                      <>
                        <div
                          style={{
                            verticalAlign: "inherit",
                            marginLeft: "5px",
                            width: "0.7rem",
                            height: "0.7rem",
                            color: "rgb(93, 132, 170)",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                        <div
                          style={{
                            verticalAlign: "inherit",
                            marginLeft: "5px",
                            width: "0.7rem",
                            height: "0.7rem",
                            color: "rgb(93, 132, 170)",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                        <div
                          style={{
                            verticalAlign: "inherit",
                            marginLeft: "5px",
                            width: "0.7rem",
                            height: "0.7rem",
                            color: "rgb(93, 132, 170)",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                      </>
                    ) : (
                      <>
                        <FiCheck /> Confirmar
                      </>
                    )}
                  </button>
                  <button
                    className="botonCancelar"
                    onClick={() => cancelar(setTemporalUser)}
                  >
                    <FiX /> Cancelar
                  </button>
                </>
              )}
            </div>

            <form className={userPSHB ? "formPSHB" : "formHB"}>
              <div className={claseCampo}>
                <span className="perfil-etiqueta">Teléfono fijo:</span>
                <span className="perfil-valor">
                  {!editando ? (
                    temporalUser.user.data.Customers.phone
                  ) : (
                    <input
                      type="text"
                      className="underlined-input"
                      name="phone"
                      maxLength={12}
                      value={temporalUser.user.data.Customers.phone}
                      onChange={changeValue}
                      onBlur={changeValue}
                      //autoFocus={true}
                    />
                  )}
                </span>
              </div>
              <div className="text-danger ms-2">{formik.errors.phone}</div>

              <div className={activo ? "lineaActiva" : "lineaInactiva"} />
              <div
                className={claseCampo}
                style={{ display: "grid", gridTemplateColumns: "140px 1fr" }}
              >
                <span className="perfil-etiqueta">Teléfono celular:</span>
                <span className="perfil-valor">
                  {!editando ? (
                    temporalUser.user.data.Customers.mobile
                  ) : (
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      className="underlined-input"
                      name="mobile"
                      maxLength={12}
                      value={temporalUser.user.data.Customers.mobile}
                      onChange={changeValue}
                      onBlur={changeValue}
                      //autoFocus={true}
                    />
                  )}
                </span>
              </div>
              <div className="text-danger ms-2">{formik.errors.mobile}</div>

              <div className={activo ? "lineaActiva" : "lineaInactiva"} />
              <div
                className="campo campo-perfil-email"
                style={{ display: "grid", gridTemplateColumns: "50px 1fr" }}
              >
                <span
                  className="perfil-etiqueta"
                  style={{ alignSelf: "flex-end" }}
                >
                  Mail:
                </span>
                <span className="perfil-valor">
                  {temporalUser.user.data.Customers.email}
                </span>
              </div>
              <div className="text-danger ms-2">{formik.errors.email}</div>
            </form>
          </div>
        </div>
        {/* FIN DESKTOP */}
      </div>
    </>
  );
};
